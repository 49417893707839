$darkBlue: #02385A;
$darkGrey: #42485A;
$lightGreen: #83A602;
$darkType: #1E2601;
$almostWhite: #FFFCFA;
$lightBlue: #B3D6FE;
$mediumBlue: #1262A5;
$beige: #F8F0EC;
$pinkBeige: #FFF9F6;
$darkBeige: #FEF2EB;
$disabled: #D9D9D9;

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.App {
  margin: 0px;
  padding: 0px;
}
body {
  margin: 0px;
  padding: 0px;
  font-family: "source-sans-pro", sans-serif;
}

#root {
  min-height: 100vh;
}

header {
  padding: 15px 30px 20px 30px;
  z-index: 1;
}

h1 {
  font-family: 'laca', sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 2.5rem;
}
h2 {
  font-family: 'laca', sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 2.5rem;
}

h3 {
  font-weight: 400;
  font-style: normal;
  font-size: 3.125rem;
  text-align: center;
}

h4 {
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem;
  text-align: center;
  color: $darkType;
  line-height: 1.75rem;
}
h5 {
  font-family: 'laca', sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 1.75rem;
}

h6 {
  font-family: 'laca', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.85rem;
}

p {
  font-size: 1.125rem;
}
p.subtext {
  font-size: 1rem;
  font-weight: 300;
}
.italic {
  font-style: italic;
}

a {
  text-decoration: none;
}

ul {
  padding: 0px;
  margin: 0px;
}
ul li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.button {
  width: 215px;
  height: 54px;
  text-align: center;
  border-radius: 4px;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 54px;
  cursor: pointer;
  display: inline-block;
}

.button.main.hidden {
  background-color: $disabled;
  pointer-events: none;
  cursor: initial;
}

.button.main:hover {
  background-color: $mediumBlue;
}

.button.main {
  background-color: $darkBlue;
  border: 0px;
  color: $almostWhite;
}

.button.secondary {
  background-color: rgba(0,0,0,0);
  border: solid 1px $darkBlue;
  color: $darkBlue;
}

.button.button.secondary:hover {
  border: solid 1px $mediumBlue;
  background-color: $beige;
}

header {
  position: absolute;
  width: 100%;
  img {
    float: left;
  }

  .linkBox {
    display: inline-block;
    font-family: 'laca', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    color: $darkBlue;
    padding-top: 15px;
  }

  .left {
    float: left;
    margin-left: 35px;
    padding-right: 20px;
  }

  .right {
    float: right;
    margin-left: 30px;
    padding-right: 20px;
  }

  .linkBox:hover {
    color: $mediumBlue;
  }

  .selected {
    border-bottom: solid 2px $darkBlue;
  }
  .selected:hover {
    border-bottom: solid 2px $mediumBlue;
  }

}
footer {
  background-color: $darkBlue;
  text-align: center;
  color: $almostWhite;
  padding: 40px 0px 10px 0px;

  h5 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 40px;
  }
}

/** Home page */

.homeOne {
  height: 636px;
  width: 100%;
  background-image: url('../img/oneTexture.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 120px;
  text-align: center;
  box-sizing: border-box;
  h1 {
    color: $lightGreen;
    font-size: 5.1875rem;
    margin-top: 5px;
  }
  h3 {
    color: $darkGrey;
  }
  h4 {
    max-width: 600px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 25px;
  }
  .button {
    display: block;
    margin: 10px auto;
  }
}
.mountains {
  position: absolute;
  left: -99px;
  top: 201px;
  pointer-events: none;
}
.water {
  position: absolute;
  right: -14px;
  top: 583px;
  pointer-events: none;
}

.homeSection {
  padding: 70px 40px;
  text-align: center;

  h1 {
    margin-bottom: 20px;
  }

  p {
    text-align: left;
  }

  ul {
    border-radius: 4px;
    padding: 10px 30px 0px 30px;
    margin-top: 20px;
    li {
      text-align: left;
      font-weight: 300;
      margin: 20px 0px 35px 0px;
      font-size: 1.25rem;
      p {
        font-size: 1.25rem;
      }
    }
  }

  a {
    color: $mediumBlue;
    text-decoration: underline;
  }
  
  a:hover {
    color: $darkBlue;
  }
  
}

.homeTwo {
  background-image: url('../img/twoTexture.png');
  background-size: cover;
  background-repeat: no-repeat;
  color: $almostWhite;

  p {
    margin: 0px;
    margin-left: 10px;

    .emphasized {
      font-weight: 600;
      color: $lightBlue;
    }
  }

  ul {
    border: solid 1px $darkBlue;
    background-color: rgba(1,56,90, .67);
    li {
      display: flex;

      img {
        align-self: center;
      }
    }
  }
}

.centerSection {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.homeThree {
  background-image: url('../img/threeTexture.png');
  background-size: cover;
  background-repeat: no-repeat;
  color: $darkType;

  h1 {
    color: $mediumBlue;
  }

  .emphasized {
    font-weight: 600;
    color: $mediumBlue;
  }

  ul {
    border: solid 1px $beige;
    background-color: rgba(255,248,245, .71);
  }
}

.homeFour {
  background-image: url('../img/fourTexture.png');
  background-size: cover;
  background-repeat: no-repeat;
  color: $darkType;

  h1 {
    color: $darkBlue;
  }

  ul li{
    display: flex;
    h1 {
      font-size: 1.25rem;
      display: inline-block;
      align-self: center;
    }
    p {
      margin-left: 10px;
    }
  }
}

/* Contribute Page (form) */

input[type="radio"], input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1em;
  height: 1em;
  background-color: white;
  border: 2px solid white;
  outline: 1px solid rgba(1,56,90,.64);
  border-radius: 50%;
  position: relative;
  top: 3px;
  cursor: pointer;
}
input[type="radio"]:checked, input[type="checkbox"]:checked {
  background-color: $darkBlue;
  border: 2px solid white;
}
input[type="checkbox"] {
  border-radius: 2px;
}
.contribute {
  width: 100%;
  padding: 120px 20px 40px 20px;
  text-align: center;
  box-sizing: border-box;
  background-color: $pinkBeige;
  color: $darkBlue;

  h1 {
    margin-bottom: 20px;
  }

  p {
    font-size: 1rem;
    color: $darkType;
  }
  p.subhead {
    color: $darkBlue;
    margin-bottom: 20px;
  }

  p.subtext {
    color: $mediumBlue;
    font-size: 1rem;
  }

  .form-question, .mapContainer {
    width: 100%;
    padding: 20px 40px;
    max-width: 1000px;
    text-align: left;
    margin: auto;

    h6 {
      padding-left: 10px;
    }

    label {
      display: block;
      cursor: pointer;
      border-radius: 4px;
      border: solid 1px $pinkBeige;
      padding: 5px 10px;


      span.form-label {
        margin-left: 10px;
      }
    }

    label:hover {
      background-color: $beige;
      border: solid 1px $darkBeige;
    }
    .inputHolder {
      display: block;
      padding: 5px 0px 0px 35px;
    }
    .optionalInput {
      width: 100%;
      border-radius: 2px;
      border: 1px solid rgba(1,56,90,.64);
      height: 35px;
      background-color: $almostWhite;
      padding: 5px;
    }
    .optionalInput:hover {
      border: 1px solid rgba(1,56,90,1);
    }
    .inactive {
      border: 1px solid rgba(1,56,90,.2);
      pointer-events:none;
      background-color: rgba(255,255,255,.3)
    }
  }
}

/** The Map */

.mapContainer {
  p {
    padding-left: 10px;
  }
}

/** the completed form page */

.complete, .data-usage {
  width: 100%;
  background-image: url('../img/oneTexture.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 20px 20px 20px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  min-height: 600px;
  h1 {
    color: $lightGreen;
    font-size: 5.1875rem;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .mountains {
    bottom: 0px;
  }

  h6 {
    margin-bottom: 40px;
  }
}

.data-usage {
  text-align: center;
  h2 {
    color: $lightGreen;
    margin-bottom: 30px;
  }
  h4 {
    color: $darkBlue;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 30px;
  }
  p {
    max-width: 800px;
    width: 100%;
    text-align: left;
    margin: auto;
  }
}

/** Media Queries */

@media screen and (max-width: 750px) {
  .mountains, .water {
    display: none;
  }
  h1 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2.5rem;
  }
  h4 {
    font-size: 1.3rem;
  }

  .homeOne {
    padding: 120px 20px 60px 20px;
    height: auto;

    h1 {
      font-size: 4.5rem;
    }
  }

  .homeSection {
    ul li {
      margin: 10px 0px 10px 0px;
    }
  }

  header .linkBox {
    margin: 0px 5px;
    font-size: 1rem;
  }

  .contribute {
    h1 {
      margin-bottom: 30px;
    }
    p {
      font-size: 1.1rem;
    }
    .subhead {
      max-width: 500px;
      margin: auto;
    }

    p.fillAll {
      margin: 20px 0px;
      font-size: 1rem;
    }
    .mapContainer {
      padding: 20px 0px;
    }
    
  }
}

@media screen and (max-width: 430px) {
  h1 {
    font-size: 2rem;
  }
  h6 {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
  .homeOne {
    h1 {
      font-size: 3rem;
    }
    h3 {
      font-size: 1.75rem;
    }
    h4 {
      font-size: 1.1rem;
    }
    .button {
      margin-top: 35px;
    }
  }
  header {
    display: flex;
    padding: 15px 30px 20px 30px;
    img {
      display: none;
    }
    width: 100%;

    .linkBox {
      float: none;
      flex-grow: 1;
    }

  }

  .homeTwo {
    p.italic {
      text-align: center;
      margin: 0px;
    }
  }

  .homeSection {
    padding: 40px 20px;
    ul {
      padding: 10px 5px 0px 5px;
      li {
      font-size: 1.05rem;
        p {
          font-size: 1.05rem;
        }
      }
      
    }
  }

  .contribute {
    .form-question {
      padding: 20px 0px;

      h6 {
        margin-bottom: 10px;
      }

      label {
        font-size: 1.1rem;
        padding: 8px 10px;
      }
    }
  }
  
  footer {
    h5 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1.05rem;
    }
    padding: 20px;
  }

  .data-usage {
    padding-top: 100px;
  }
  
}